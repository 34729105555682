






















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheLoginAuthForm',
  components: {
    BaseInput: () =>
      import(
        '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'
      ),
  },
})
export default class TheLoginAuthForm extends Vue {
  @Prop({ type: String, default: false }) readonly error!: string
  @Prop({ type: String, required: true }) readonly login!: string
  @Prop({ type: String, required: true }) readonly password!: string

  onInput (event: InputEvent, target: 'password' | 'login') {
    if (target === 'password') this.$emit('update:password', event)
    else this.$emit('update:login', event)

    this.$emit('update:error', '')
  }
}
